// TODO: Re enable console warnings when we move to a proper logging system: https://faro01.atlassian.net/browse/SWEB-459
/* eslint-disable no-console */
import { GUID, IElement, IPose, isIElemLink } from "@faro-lotv/ielement-types";
import { DataSetAreaInfo, ProjectAccessLevel } from "@faro-lotv/service-wires";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clearStore } from "./actions";
import {
  TreeData,
  findTreeItem,
  generateProjectTree,
} from "./utils/tree-generation";
import {
  WorldTransformCache,
  updateWorldTransformCacheSubTree,
} from "./utils/world-transform-cache";

/**
 * A collection of IElements, indexed by their ID.
 *
 * The value can be undefined if the element has not been loaded yet.
 */
export type IElementsRecord = Record<GUID, IElement | undefined>;

export type IElementsFetcher = {
  /** A function that will load and return IElements */
  fetcher(): Promise<IElement[]>;
  /** Optional id of the element being loaded, this element will display a loading indicator in the sidebar */
  loadingIds?: GUID[];
};

/**
 * Fetch some of the IElements of the project
 * Optionally set an element as the root of the tree for loading indication
 */
export const fetchProjectIElements = createAsyncThunk(
  "ielements/fetchProjectIElements",
  async (
    { fetcher, loadingIds }: IElementsFetcher,
    { dispatch, rejectWithValue },
  ) => {
    loadingIds?.map((id) => dispatch(addToLoadingRoots(id)));
    let elements: IElement[] = [];
    try {
      elements = await fetcher().finally(() => {
        loadingIds?.map((id) => dispatch(removeFromLoadingRoots(id)));
      });
    } catch (error) {
      return rejectWithValue(error);
    }

    if (!elements.length) return elements;

    const root = elements.find((el) => el.id === elements[0].rootId);
    dispatch(addIElements(elements));
    if (root) {
      dispatch(setRootId(root.id));
    }

    return elements;
  },
);

export type State = { iElements: IElementsState };

/**
 * Loading state of the project data
 *
 * * undefined -> project has not been triggered to load yet
 * * loading -> project is currently being loaded
 * * done -> project has been successfully loaded into the store
 * * failed -> there was an error loading the project
 */
export type ProjectLoadingState = undefined | "loading" | "done" | "failed";

export interface IElementsState {
  /** ID of the project tracked by the store */
  projectId: GUID | undefined;

  /** Name of the current loaded project */
  projectName: string | undefined;

  /** Access level of the current loaded project i.e whether the project is private, unlisted or public */
  projectAccessLevel: ProjectAccessLevel | undefined;

  /** ID of the company that contains the project */
  companyId: GUID | undefined;

  /** Url for the proper dashboard for this project */
  dashboardUrl: string | undefined;

  /** Map of all the IElements with the ID as key */
  iElements: IElementsRecord;

  /** Cached World transformation for all the IElements */
  worldTransformCache: WorldTransformCache;

  /** ID of the project root */
  rootId?: GUID;

  /** The ID root of every tree that is currently being loaded */
  loadingSubTrees: GUID[];

  /** Loading state of the project data */
  projectLoadingState: ProjectLoadingState;

  /** Tree representation of the IElements */
  tree: TreeData[];

  /** Enable/Disable tree filters */
  shouldFilterProjectTree: boolean;

  /** Map each area with the datasets that are contained in its volume */
  areaDataSets: Record<GUID, DataSetAreaInfo[] | undefined>;
}

type ActionOnIElement<T> = PayloadAction<
  {
    id: GUID;
  } & T
>;

export const IELEMENTS_SLICE_INITIAL_STATE: IElementsState = Object.freeze({
  projectId: undefined,
  projectName: undefined,
  projectAccessLevel: undefined,
  companyId: undefined,
  dashboardUrl: undefined,
  iElements: {},
  worldTransformCache: {},
  rootId: undefined,
  loadingSubTrees: [],
  projectLoadingState: undefined,
  tree: [],
  shouldFilterProjectTree: true,
  areaDataSets: {},
});

export const iElementsSlice = createSlice({
  name: "ielements",
  initialState: IELEMENTS_SLICE_INITIAL_STATE,
  reducers: {
    /**
     * Initialize the project in the store, compute the root and initialize the world position caches
     *
     * @param state The slice state
     * @param action ID of the project
     */
    initializeProject(state, action: PayloadAction<GUID | undefined>) {
      if (state.projectId !== action.payload) {
        state.iElements = {};
        state.worldTransformCache = {};
        state.rootId = undefined;
      }
      state.projectId = action.payload;
    },

    /**
     * Update the company ID to identify what company contains the current project
     *
     * @param state The slice state
     * @param action The id of the company that contains the current project
     */
    setCompanyId(state, action: PayloadAction<GUID | undefined>) {
      state.companyId = action.payload;
    },

    /**
     * Update the name of the current project
     *
     * @param state The slice state
     * @param action The name of the current project
     */
    setProjectName(state, action: PayloadAction<string | undefined>) {
      state.projectName = action.payload;
    },

    /**
     * Update the access level of the current project
     *
     * @param state The slice state
     * @param action The access level of the current project
     */
    setProjectAccessLevel(
      state,
      action: PayloadAction<ProjectAccessLevel | undefined>,
    ) {
      state.projectAccessLevel = action.payload;
    },

    /**
     * Update the url to open the dashboard for the current project
     *
     * @param state The slice state
     * @param action The url for the current project dashboard
     */
    setDashboardUrl(state, action: PayloadAction<string | undefined>) {
      state.dashboardUrl = action.payload;
    },

    /**
     * Add the iElements to the store
     *
     * @param state The slice state
     * @param action The list of IElements
     * @deprecated Use {@link initializeProject} instead to make sure the rootId and the cache is synchronized
     */
    addIElements(state, action: PayloadAction<IElement[]>) {
      const elements = action.payload;

      for (const element of elements) {
        state.iElements[element.id] = element;
      }

      // As this will not re-compute existing values in cache
      // when we add multiple elements it's easier to update the entire cache
      updateWorldTransformCacheSubTree(
        state.iElements,
        state.worldTransformCache,
        state.areaDataSets,
        state.rootId,
      );

      state.tree = generateProjectTree(
        elements[0].rootId,
        state.iElements,
        state.areaDataSets,
        state.shouldFilterProjectTree,
      );
    },

    /**
     * Set the root id for the project
     *
     * @param state The slice state
     * @param action The new root id
     * @deprecated Use {@link initializeProject} instead to make sure the rootId and the cache is synchronized
     */
    setRootId(state, action: PayloadAction<GUID>) {
      state.rootId = action.payload;
    },

    /**
     * Add an id to the list of roots that are being loaded.
     *
     * @param state - The slice state
     * @param {PayloadAction} action - The id of the element that is being loaded
     */
    addToLoadingRoots(state, action: PayloadAction<GUID>) {
      state.loadingSubTrees.push(action.payload);
    },

    /**
     * Remove an id from the list of roots that are being loaded.
     *
     * @param state - The slice state
     * @param {PayloadAction} action - The id of the element that is done being loaded
     */
    removeFromLoadingRoots(state, action: PayloadAction<GUID>) {
      const index = state.loadingSubTrees.indexOf(action.payload);
      if (index === -1) return;
      state.loadingSubTrees.splice(index, 1);
    },

    /**
     * Remove an element and all its children recursively
     *
     * @param state Current state
     * @param action ID of the IElement to remove
     */
    removeIElement(state, action: PayloadAction<GUID>) {
      const iElement = state.iElements[action.payload];

      if (!iElement) {
        return;
      }

      const deletedElements = new Set(iElement.id);

      // remove reference of this element from its parent
      const parent = state.iElements[iElement.parentId ?? ""];

      if (parent) {
        parent.childrenIds =
          parent.childrenIds?.filter((x) => x !== iElement.id) ?? null;
      }

      // Remove this element and all its children from the store
      function recursiveRemoveIElement(id: GUID): void {
        const el = state.iElements[id];

        if (!el) return;

        const children = el.childrenIds ?? [];
        for (const child of children) {
          recursiveRemoveIElement(child);
        }
        deletedElements.add(id);
        delete state.iElements[id];
        delete state.worldTransformCache[id];
      }
      recursiveRemoveIElement(action.payload);

      // Remove elements which link to any of the deleted elements
      const linkingElements = [];
      for (const [id, el] of Object.entries(state.iElements)) {
        if (!el) continue;
        if (isIElemLink(el) && deletedElements.has(el.target_Id)) {
          // Don't delete the element directly here to avoid modifying the object we are iterating over
          linkingElements.push(id);
        }
      }
      for (const id of linkingElements) {
        delete state.iElements[id];
        delete state.worldTransformCache[id];
      }

      state.tree = generateProjectTree(
        iElement.rootId,
        state.iElements,
        state.areaDataSets,
        state.shouldFilterProjectTree,
      );
    },

    changePosition(state, { payload }: ActionOnIElement<{ pose: IPose }>) {
      const { id, pose } = payload;
      const iElement = state.iElements[id];

      if (iElement) {
        iElement.pose = pose;

        updateWorldTransformCacheSubTree(
          state.iElements,
          state.worldTransformCache,
          state.areaDataSets,
          id,
        );
      } else {
        console.warn(`IElement with id ${id} not found`);
      }
    },

    setGeoReferenced(state, action: PayloadAction<GUID>) {
      const iElementId = action.payload;
      const iElement = state.iElements[iElementId];

      // iElement might be undefined in case of `iElementId` being invalid
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (iElement) {
        iElement.pose = {
          isWorldPose: true,
          isWorldRot: true,
          isWorldScale: true,
          pos: null,
          rot: null,
          scale: null,
          gps: null,
        };

        updateWorldTransformCacheSubTree(
          state.iElements,
          state.worldTransformCache,
          state.areaDataSets,
          iElementId,
        );
      } else {
        console.warn(`IElement with id ${iElementId} not found`);
      }
    },

    changeName(state, { payload }: ActionOnIElement<{ name: string }>) {
      const { id, name } = payload;
      const iElement = state.iElements[id];

      if (iElement) {
        iElement.name = name;
      } else {
        console.warn(`IElement with id ${id} not found`);
      }

      const treeItem = findTreeItem(id, state.tree);
      if (treeItem) {
        treeItem.label = name;
        treeItem.disableTranslation = true;
      }
    },

    changeDescr(state, { payload }: ActionOnIElement<{ descr: string }>) {
      const { id, descr } = payload;
      const iElement = state.iElements[id];

      if (iElement) {
        iElement.descr = descr;
      } else {
        console.warn(`IElement with id ${id} not found`);
      }
    },

    addChild(state, { payload }: ActionOnIElement<{ childId: GUID }>) {
      const { id, childId } = payload;
      const iElement = state.iElements[id];

      if (iElement) {
        iElement.childrenIds = [...(iElement.childrenIds ?? []), childId];

        updateWorldTransformCacheSubTree(
          state.iElements,
          state.worldTransformCache,
          state.areaDataSets,
          id,
        );
        if (state.rootId) {
          state.tree = generateProjectTree(
            state.rootId,
            state.iElements,
            state.areaDataSets,
            state.shouldFilterProjectTree,
          );
        }
      } else {
        console.warn(`IElement with id ${id} not found`);
      }
    },

    moveIElement(
      state,
      {
        payload,
      }: ActionOnIElement<{
        newParentId: GUID;
        oldParentId: GUID;
        // -1 means insert as last
        moveIndexInTargetParent?: number;
      }>,
    ) {
      const {
        id,
        oldParentId,
        newParentId,
        moveIndexInTargetParent = -1,
      } = payload;

      const iElement = state.iElements[id];
      const oldParent = state.iElements[oldParentId];
      const newParent = state.iElements[newParentId];

      // iElement might be undefined in case of `id` being invalid
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!iElement) {
        console.warn(`IElement with id ${id} not found`);
        return;
      }

      // oldParent might be undefined in case of `oldParentId` being invalid
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!oldParent) {
        console.warn(`IElement with id ${oldParentId} not found`);
        return;
      }

      // newParent might be undefined in case of `newParentId` being invalid
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!newParent) {
        console.warn(`IElement with id ${newParentId} not found`);
        return;
      }

      oldParent.childrenIds = oldParent.childrenIds
        ? oldParent.childrenIds.filter((existingId) => existingId !== id)
        : [];

      // FIXME: https://faro01.atlassian.net/browse/SWEB-506
      if (moveIndexInTargetParent) {
        newParent.childrenIds?.splice(moveIndexInTargetParent, 0, id);
      } else {
        newParent.childrenIds = newParent.childrenIds
          ? [...newParent.childrenIds, id]
          : [id];
      }

      iElement.parentId = newParent.id;
      // Update the world positions of the moved subtree
      updateWorldTransformCacheSubTree(
        state.iElements,
        state.worldTransformCache,
        state.areaDataSets,
        id,
      );

      if (state.rootId) {
        state.tree = generateProjectTree(
          state.rootId,
          state.iElements,
          state.areaDataSets,
          state.shouldFilterProjectTree,
        );
      }
    },

    changeIndexInParent(
      state,
      {
        payload,
      }: ActionOnIElement<{
        childToMove: GUID;
        moveIndexInTargetParent: number;
      }>,
    ) {
      const { id, childToMove, moveIndexInTargetParent } = payload;

      const parent = state.iElements[id];

      // oldParent might be undefined in case of `id` being invalid
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!parent) {
        console.warn(`IElement with id ${id} not found`);
        return;
      }

      const fromIndex = parent.childrenIds?.findIndex(
        (existingId) => existingId === childToMove,
      );

      if (!fromIndex) {
        console.warn(`childToMove with id ${childToMove} not found`);
        return;
      }

      // Removes element from `fromIndex` and inserts that element into `moveIndexInTargetParent` position
      parent.childrenIds?.splice(
        moveIndexInTargetParent,
        0,
        ...parent.childrenIds.splice(fromIndex, 1),
      );

      const parentTreeItem = findTreeItem(parent.id, state.tree);
      if (parentTreeItem?.children) {
        parentTreeItem.children.splice(
          moveIndexInTargetParent,
          0,
          ...parentTreeItem.children.splice(fromIndex, 1),
        );
      }
    },

    /**
     * Update the world transform cache for a sub-tree of the project, starting from an element
     * evaluating all the children
     *
     * @param state The slice state
     * @param param1 The Action payload
     * @param param1.payload The ID of the element to start the sub-tree update
     */
    updateWorldTransformCache(state, { payload }: PayloadAction<GUID>) {
      updateWorldTransformCacheSubTree(
        state.iElements,
        state.worldTransformCache,
        state.areaDataSets,
        payload,
      );
    },

    /**
     * Disable the tree filtering
     *
     * @param state The slice state
     */
    disableTreeFiltering(state) {
      state.shouldFilterProjectTree = false;

      if (state.rootId) {
        state.tree = generateProjectTree(
          state.rootId,
          state.iElements,
          state.areaDataSets,
          state.shouldFilterProjectTree,
        );
      }
    },

    /**
     * Add to the store the datasets for a specific area, replacing the info if already present for that area
     *
     * @param state the slice state
     */
    addAreaDataSets(
      state,
      {
        payload,
      }: PayloadAction<{
        areaId: GUID;
        dataSets: DataSetAreaInfo[];
      }>,
    ) {
      state.areaDataSets = {
        ...state.areaDataSets,
        [payload.areaId]: payload.dataSets,
      };

      if (state.rootId) {
        state.tree = generateProjectTree(
          state.rootId,
          state.iElements,
          state.areaDataSets,
          state.shouldFilterProjectTree,
        );
        updateWorldTransformCacheSubTree(
          state.iElements,
          state.worldTransformCache,
          state.areaDataSets,
          state.rootId,
        );
      }
    },
    /**
     * Set to the store the mapping between areas and dataset, replacing the entire previous mapping if present
     *
     * @param state the slice state
     */
    setAreaDataSets(
      state,
      { payload }: PayloadAction<Record<GUID, DataSetAreaInfo[]>>,
    ) {
      state.areaDataSets = payload;
      if (state.rootId) {
        state.tree = generateProjectTree(
          state.rootId,
          state.iElements,
          state.areaDataSets,
          state.shouldFilterProjectTree,
        );
        updateWorldTransformCacheSubTree(
          state.iElements,
          state.worldTransformCache,
          state.areaDataSets,
          state.rootId,
        );
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearStore, () => IELEMENTS_SLICE_INITIAL_STATE);

    // Handling the state of the fetchProjectIElements thunk
    builder.addCase(fetchProjectIElements.pending, (state) => {
      state.projectLoadingState = "loading";
    });
    builder.addCase(fetchProjectIElements.fulfilled, (state) => {
      state.projectLoadingState = "done";
    });
    builder.addCase(fetchProjectIElements.rejected, (state) => {
      state.projectLoadingState = "failed";
    });
  },
});

export const {
  initializeProject,
  setCompanyId,
  setProjectName,
  setProjectAccessLevel,
  setDashboardUrl,
  addIElements,
  removeIElement,
  setRootId,
  changePosition,
  setGeoReferenced,
  changeName,
  changeDescr,
  addChild,
  moveIElement,
  changeIndexInParent,
  updateWorldTransformCache,
  addToLoadingRoots,
  removeFromLoadingRoots,
  disableTreeFiltering,
  setAreaDataSets,
  addAreaDataSets,
} = iElementsSlice.actions;

export const iElementsReducer = iElementsSlice.reducer;
